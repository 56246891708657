<script setup>
import { wanderData } from '@/mock/index.js'
function toMore(link) {
  if (link) {
    window.open(link, '_blank')
  }
}
</script>
<template>
  <div class="body">
    <img class="bodyBgc" :src="wanderData.h5Img" alt="背景图" />
    <div class="bodyNav">
      <div class="bodyNavTitle">{{ wanderData.title }}</div>
      <div class="bodyNavDesc">
        {{ wanderData.desc }}
      </div>
      <img
        class="bodyNavBtn"
        :src="wanderData.h5btn"
        alt="立即前往"
        @click="toMore(wanderData.moreUrl)"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.body {
  position: relative;
  &Bgc {
    width: 100%;
    height: 100%;
  }
  &Nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 267px;
    color: #fff;
    text-align: center;
    &Title {
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
    }
    &Desc {
      font-size: 13px;
      line-height: 24px;
      margin: 18px 0 42px;
      text-align: left;
    }
    &Btn {
      width: 110px;
      height: 36px;
    }
  }
}
</style>
