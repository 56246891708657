<script setup>
import { wanderData } from '@/mock/index.js'
function toMore(url) {
  window.open(url)
}
</script>
<template>
  <div class="body">
    <div class="bodyMask"></div>
    <img class="bodyBgc" :src="wanderData.aImg" alt="背景图" />
    <div class="bodyNav">
      <div class="bodyNavTitle">{{ wanderData.title }}</div>
      <div class="bodyNavDesc">{{ wanderData.desc }}</div>
      <img
        class="bodyNavBtn"
        :src="wanderData.btn"
        alt="按钮"
        @click="toMore(wanderData.moreUrl)"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.body {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  &Mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  &Bgc {
    width: 100%;
    height: 100%;
  }
  &Nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    color: #fff;
    text-align: center;
    z-index: 2;
    &Title {
      font-family: TypeRegular;
      font-size: 80px;
      font-weight: bold;
      line-height: 80px;
    }
    &Desc {
      text-align: justify;
      margin: 40px 0 40px;
      font-size: 28px;
      line-height: 42px;
      letter-spacing: 0px;

      color: #ffffff;
    }
    &Btn {
      cursor: pointer;
      display: inline-block;
      width: 246px;
      height: 81px;
      margin: auto;
    }
  }
}
</style>
